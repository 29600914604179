import React, { useContext, useEffect, useRef, useState } from "react";
import { MultiSelect, ComboBox } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Button } from "@progress/kendo-react-buttons";
import { FilterContext } from "../Contexts/FilterContext";
import { CalcDate } from "../CalcFilterValue";
import { withRouter } from "react-router-dom";
import { DefaultValue } from "../Constants/DefaultValue";
import { Error } from "@progress/kendo-react-labels";
import $ from "jquery";

import { Window } from "@progress/kendo-react-dialogs";
import { runStatus } from "../Common/Utilities";
import { Input } from "@progress/kendo-react-inputs";

const Filter = (props) => {
  const [saveViewWindowVisible, setSaveViewWindowVisible] =
    React.useState(false);
  const [savedViews, setSavedViews] = useState([]);
  const [currentViewName, setCurrentViewName] = useState({});
  const [enableSaveViewButton, setEnabledSaveViewButton] = useState(false);
  const [selectedSearch, setSelectedSearch] = useState("");
  //const [pProduct, setpProduct] = useState("");

  const toggleSavewViewDialog = () => {
    setSaveViewWindowVisible(!saveViewWindowVisible);
  };

  const SetQueryStringParam = (name, value) => {
    return value ? `${name}=${value.toString()}` : "";
  };

  const style = {
    width: "370px",
  };

  const {
    Product,
    setProduct,
    Release,
    setRelease,
    Milestone,
    setMilestone,
    RunStatus,
    setRunStatus,
    BaselineType,
    setBaselineType,
    MachineType,
    setMachineType,
    options,
    ShowMore,
    setShowMore,
    location,
    QueryString,
    setQueryString,
    history,
    Show,
    setShow,
    setBody,
    Page_No,
    setPage_No,
    Data,
    setData,
    InitialLoading,
    setInitialLoading,
    ShowExceptionPopup,
    setShowExceptionPopup,
    CorrelationId,
    setCorrelationId,
    // ShowRepairItemPopup,
    // setShowRepairItemPopup,
    SearchText,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    MilestoneType,
    setMilestoneType,
  } = useContext(FilterContext);

  const handleClear = () => {
    setProduct([]);
    setRelease([]);
    setMilestone([]);
    setRunStatus([]);
    setBaselineType([]);
    setMachineType([]);
    setMilestoneType(["Experiment"]);
    setFromDate(DefaultValue.FromDate);
    setToDate(DefaultValue.ToDate);
  };

  const createQueryString = () => {
    let qs = `${SetQueryStringParam(
      "Product",
      Product.toString()
    )}&${SetQueryStringParam(
      "Release",
      Release.toString()
    )}&${SetQueryStringParam(
      "Milestone",
      Milestone.length ? Milestone.map((m) => m.id) : [] //Milestone.toString()
    )}&${SetQueryStringParam(
      "RunStatus",
      RunStatus.toString()
    )}&${SetQueryStringParam(
      "BaselineType",
      BaselineType.toString()
    )}&${SetQueryStringParam(
      "MachineType",
      MachineType.toString()
    )}&${SetQueryStringParam(
      "MilestoneType",
      MilestoneType.toString()
    )}&startDate=${fromDate?.toISOString().split("T")[0]}&endDate=${
      toDate?.toISOString().split("T")[0]
    }`;
    return qs;
  };

  const getViews = () => {
    const storedValue = localStorage.getItem("viewsWUPL");
    if (storedValue) {
      setSavedViews(DefaultValue.CommonViews.concat(JSON.parse(storedValue)));
    } else {
      setSavedViews(DefaultValue.CommonViews);
    }
  };

  const updateFilterView = () => {
    if (!QueryString) return;
    let urlParameters = new URLSearchParams(QueryString);

    console.log("Product", urlParameters.get("Product"));

    let pProduct = urlParameters.get("Product")
      ? urlParameters.get("Product").split(",")
      : [];
    if (pProduct != null && pProduct != undefined) setProduct(pProduct);

    console.log("Release", urlParameters.get("Release"));
    let pRelease = urlParameters.get("Release")
      ? [urlParameters.get("Release")]
      : [];
    if (pRelease != null && pRelease != undefined) setRelease(pRelease);

    console.log("Milestone", urlParameters.get("Milestone"));
    let pMilestone =
      urlParameters.get("Milestone") != null &&
      urlParameters.get("Milestone") != undefined
        ? urlParameters.get("Milestone").split(",")
        : [];
    if (pMilestone != null && pMilestone != undefined)
      setMilestone(
        options.Milestone.filter(
          (m) => pMilestone.indexOf(m.id.toString()) !== -1
        )
      );

    console.log("RunStatus", urlParameters.get("RunStatus"));
    let pRunStatus = urlParameters.get("RunStatus")
      ? urlParameters.get("RunStatus").split(",")
      : [];
    if (pRunStatus != null && pRunStatus != undefined) setRunStatus(pRunStatus);

    console.log("BaselineType", urlParameters.get("BaselineType"));
    let pBaselineType = urlParameters.get("BaselineType")
      ? urlParameters.get("BaselineType").split(",")
      : [];
    if (pBaselineType != null && pBaselineType != undefined)
      setBaselineType(pBaselineType);

    console.log("MachineType", urlParameters.get("MachineType"));
    let pMachineType = urlParameters.get("MachineType")
      ? urlParameters.get("MachineType").split(",")
      : [];
    if (pMachineType != null && pMachineType != undefined)
      setMachineType(pMachineType);

    console.log("MilestoneType", urlParameters.get("MilestoneType"));
    let pMilestoneType = urlParameters.get("MilestoneType")
      ? urlParameters.get("MilestoneType").split(",")
      : [];
    if (pMilestoneType != null && pMilestoneType != undefined)
      setMilestoneType(pMilestoneType);

    let sDate = urlParameters.get("startDate");
    if (sDate !== null && sDate != undefined) setFromDate(new Date(sDate));

    let tDate = urlParameters.get("endDate");
    if (tDate !== null && tDate != undefined) setToDate(new Date(tDate));
  };

  const handleApply = () => {
    setData([]);
    setPage_No(1);
    setInitialLoading(true);
    setBody({
      Product: Product,
      Release: Release,
      Milestone: Milestone.length ? Milestone.map((m) => m.id) : [],
      RunStatus: RunStatus,
      BaselineType: BaselineType,
      MachineType: MachineType,
      MilestoneType: MilestoneType,
      dateRange: CalcDate(fromDate, toDate),
    });

    if (props.location?.pathname !== "/") history.push("/");
  };

  useEffect(() => {
    setQueryString(selectedSearch);
  }, [selectedSearch]);

  useEffect(() => {
    updateFilterView();
  }, [QueryString]);

  useEffect(() => {
    getViews();
    // Handle accessibility warnings in code
    $(".k-input-values").attr("title", "FilterValues");
    $(".k-input-values").attr("role", "listitem");
    $(".k-input").removeAttr("role");
    $(".k-input").removeAttr("aria-expanded");
    $(".k-input").removeAttr("aria-owns");

    $(".k-input").removeAttr("aria-valuemin");
    $(".k-input").removeAttr("aria-valuemax");
    $(".k-input").removeAttr("aria-valuetext");
    $(".k-input").removeAttr("aria-valuenow");

    $(".k-chip").attr("role", "group");
    $(".k-chip-solid").attr("role", "group");
    $(".k-chip-solid").removeAttr("aria-checked");
    $(".k-chip").attr("title", "multiMilestone");
    $(".k-select").removeAttr("aria-label");
    $("ul.k-reset").attr("title", "milestone type");
    // Handle accessibility warnings in code
    var params = new URLSearchParams(
      QueryString
        ? QueryString
        : `${SetQueryStringParam(
            "Product",
            Product.toString()
          )}&${SetQueryStringParam(
            "Release",
            Release.toString()
          )}&${SetQueryStringParam(
            "Milestone",
            Milestone.length ? Milestone.map((m) => m.id) : []
          )}&${SetQueryStringParam(
            "RunStatus",
            RunStatus.toString()
          )}&${SetQueryStringParam(
            "BaselineType",
            BaselineType.toString()
          )}&${SetQueryStringParam(
            "MachineType",
            MachineType.toString()
          )}&${SetQueryStringParam(
            "MilestoneType",
            MilestoneType.toString()
          )}&startDate=${fromDate?.toISOString().split("T")[0]}&endDate=${
            toDate?.toISOString().split("T")[0]
          }`
    );

    var state = history.location.state;
    state = {
      ...state,
      ExData: Data.length
        ? Data
        : history.location.state
        ? history.location.state.ExData
        : [],
    };
    history.push({
      search: params.toString(),
      state: state,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [QueryString]);

  useEffect(() => {
    var params = new URLSearchParams(
      QueryString
        ? QueryString
        : `${SetQueryStringParam(
            "Product",
            Product.toString()
          )}&${SetQueryStringParam(
            "Release",
            Release.toString()
          )}&${SetQueryStringParam(
            "Milestone",
            Milestone.length ? Milestone.map((m) => m.id) : []
          )}&${SetQueryStringParam(
            "RunStatus",
            RunStatus.toString()
          )}&${SetQueryStringParam(
            "BaselineType",
            BaselineType.toString()
          )}&${SetQueryStringParam(
            "MachineType",
            MachineType.toString()
          )}&${SetQueryStringParam(
            "MilestoneType",
            MilestoneType.toString()
          )}&startDate=${fromDate?.toISOString().split("T")[0]}&endDate=${
            toDate?.toISOString().split("T")[0]
          }`
    );

    var state = history.location.state;
    state = {
      ...state,
      ExData: Data.length
        ? Data
        : history.location.state
        ? history.location.state.ExData
        : [],
    };

    history.push({
      search: params.toString(),
      state: state,
    });
  }, [Data]);

  const saveView = () => {
    setCurrentViewName({
      name: currentViewName?.name,
      query: createQueryString(),
    });
    let value = [];
    const storedValue = localStorage.getItem("viewsWUPL");
    if (storedValue) {
      value = JSON.parse(storedValue);
    }

    if (currentViewName?.name && currentViewName?.query) {
      let previous = getPreviousViewVersion(value, currentViewName?.name);
      if (previous != -1) value[previous].query = currentViewName?.query;
      else value.push(currentViewName);
      localStorage.setItem("viewsWUPL", JSON.stringify(value));
      setSavedViews(DefaultValue.CommonViews.concat(value));
      toggleSavewViewDialog();
    }
  };

  const getPreviousViewVersion = (views, name) => {
    return views.findIndex((v) => v.name.toLowerCase() === name.toLowerCase());
  };

  const handleChangeViewName = (e) => {
    setCurrentViewName({ name: e.target.value, query: createQueryString() });
    setEnabledSaveViewButton(e.target.value.trim().length);
  };

  return (
    <div>
      {savedViews && savedViews.length > 0 && (
        <div className={"filter-filters-margin"}>
          <ComboBox
            id={"saved-views"}
            title={"Saved Views"}
            data={savedViews}
            defaultValue={[]}
            textField="name"
            dataItemKey="name"
            onChange={(event) => {
              console.log(event?.value?.query);
              setSelectedSearch(event?.value?.query);
            }}
            style={style}
            label={"Saved Views"}
          />
        </div>
      )}

      <div className={"filter-filters-margin"}>
        <MultiSelect
          id={"product-editor"}
          title={"productDropdown"}
          data={options.Product}
          defaultValue={[]}
          value={Product}
          onChange={(event) => {
            setProduct(event.target.value);
          }}
          style={style}
          label={"Product"}
        />
      </div>
      <div className={"filter-filters-margin"}>
        <MultiSelect
          id={"milestone-editor"}
          title="milestoneDropdown"
          data={options.Milestone}
          defaultValue={[]}
          value={Milestone}
          textField="name"
          dataItemKey="id"
          onChange={(event) => {
            setMilestone(event.target.value);
          }}
          style={style}
          label={"Milestone"}
        />
      </div>
      <div className={"filter-filters-margin"}>
        <MultiSelect
          id={"milestone-type-editor"}
          data={options.MilestoneType}
          value={MilestoneType}
          autoWidth={false}
          fillMode={"solid"}
          onChange={(event) => {
            setMilestoneType(event.target.value);
          }}
          style={style}
          label={"Milestone Type"}
        />
      </div>
      <div className={"filter-filters-margin"}>
        <MultiSelect
          id={"run-status-editor"}
          data={options.RunStatus}
          defaultValue={[]}
          value={RunStatus}
          fillMode={"solid"}
          onChange={(event) => {
            setRunStatus(event.target.value);
          }}
          style={style}
          label={"Run Status"}
        />
      </div>
      <div className={"filter-filters-margin"}>
        <MultiSelect
          id={"base-line-editor"}
          data={options.BaselineType}
          defaultValue={[]}
          value={BaselineType}
          fillMode={"solid"}
          onChange={(event) => {
            setBaselineType(event.target.value);
          }}
          style={style}
          label={"Baseline Type"}
        />
      </div>
      <div className={"filter-filters-margin"}>
        <MultiSelect
          id={"machine-type-editor"}
          data={options.MachineType}
          defaultValue={[]}
          value={MachineType}
          fillMode={"solid"}
          onChange={(event) => {
            setMachineType(event.target.value);
          }}
          style={style}
          label={"Machine Type"}
        />
      </div>
      <div className={"filter-filters-margin"}>
        <div className="filter-row">
          <div>
            <DatePicker
              label="From date"
              width={130}
              value={fromDate}
              onChange={(event) => setFromDate(event.target.value)}
            />
          </div>
          <div className="check">
            <DatePicker
              label="To date"
              width={130}
              value={toDate}
              onChange={(event) => setToDate(event.target.value)}
            />
          </div>
        </div>
      </div>

      {/* <DatePicker value={value} onChange={handleChange} /> */}

      <div className="f-footer">
        <a
          className="filter-clearall"
          title="Clear all fields"
          role="button"
          tabIndex={0}
          onClick={handleClear}
        >
          Clear All
        </a>
        <span className="filter-save-view">
          <Button
            id="saveViewBtn"
            type="button"
            onClick={() => toggleSavewViewDialog()}
          >
            Save View
          </Button>
        </span>
        <span className="filter-neutralLine">
          <Button id="submitFilter" type="button" onClick={handleApply}>
            Apply
          </Button>
        </span>
      </div>
      {saveViewWindowVisible && (
        <Window
          title={"Save View"}
          onClose={toggleSavewViewDialog}
          initialHeight={235}
          initialWidth={400}
          resizable={false}
        >
          <div style={{ marginTop: "15px", marginBottom: "15px" }}>
            <div style={{ marginTop: "15px" }}>
              <p>Please notice. Views are stored only in this device.</p>
            </div>

            <Input
              className="k-input"
              value={currentViewName.name}
              style={{ width: "348px" }}
              onChange={handleChangeViewName}
              label="View Name"
            />
          </div>

          <div
            style={{
              marginTop: "20px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Button
              type="button"
              className="filterButton"
              onClick={toggleSavewViewDialog}
            >
              Cancel
            </Button>
            <Button
              type="button"
              className="filterButton"
              onClick={saveView}
              disabled={!enableSaveViewButton}
            >
              Submit
            </Button>
          </div>
        </Window>
      )}
    </div>
  );
};

export default Filter;
